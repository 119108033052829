import React, {useState} from 'react'
import moment from 'moment'
import {InputLabelProps} from './InputLabel'
import {sharedStyles} from '../../style/shared_styles'
import {ErrorField, InputLabel} from './index'
import {ErrorFieldProps} from './ErrorField'
import {i18n, useLocale} from '../../i18n'
import {useDefaultValueSet} from './utils/useDefaultValueSet'
import 'moment/locale/de'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
/**
 * Custom component used for rendering a date time picker select.
 * This component is a generic one and will be used for all date selections.
 */
const DateInput: React.FC<DateInputProps> = (props: DateInputProps) => {
    // const [currentLocale] = useLocale();
    const [inputValue, setInputValue] = useState<string>(props.value ? moment(props.value).format('YYYY-MM-DD') : '');

    useDefaultValueSet(props.value, props.onValueChanged, null, props.skipInitialize)

    const inputStyle = props.error ? sharedStyles.border : {}
    const minDate = props.min ? moment(props.min).format('YYYY-MM-DD') : undefined;
    const maxDate = props.max ? moment(props.max).format('YYYY-MM-DD') : undefined;

    const {id, ...labelProps} = props

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log("handleInputChange triggered with value:", event.target.value);
        const date = event.target.value;
        const momentDate = moment(date, 'YYYY-MM-DD', true);
		
		let errorMessage = '';

		if(momentDate.isValid()) {
			const theDate = momentDate.toDate();
            // Einhaltung Grenzwerte checken
            if (props.min && moment(theDate).isBefore(moment(props.min), 'day')) {
				errorMessage = i18n.t('common.errors.dateHigherOrSame', {min: moment(props.min).format('DD.MM.YYYY')});
            } else if (props.max && moment(theDate).isAfter(moment(props.max), 'day')) {
				errorMessage = i18n.t('common.errors.dateLowerOrSame', {max: moment(props.max).format('DD.MM.YYYY')});
            } 
		}
		else if(props.mandatory) {
			errorMessage = i18n.t('common.errors.empty');
		}

        // const errorMessage = props.mandatory && !momentDate.isValid() ? i18n.t('common.errors.empty') : '';
        props.onValueChanged(momentDate.isValid() ? momentDate.toDate() : null, errorMessage);
        setInputValue(event.target.value);
    };

    return (
        <div>
            <InputLabel {...labelProps} />
            <input
                id={props.id}
                type="date"
                value={inputValue}
                onChange={onChange}
                onBlur={props.onBlur}
                className="ant-input"
                style={{ ...inputStyle, width: '100%' }}
                min={minDate}
                max={maxDate}
            />
            <ErrorField error={props.error} warn={props.warn} />
        </div>
    )
}

interface DateInputProps extends InputLabelProps, ErrorFieldProps {
    onValueChanged: (val: any, error?: string) => void
    value?: Date
    id?: string
    min?: Date
    max?: Date
    defaultPickerValue?: Date
    onBlur?: () => void
}

export default DateInput
