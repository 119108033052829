import React, {useEffect, useState} from 'react'
import {CustomModal, CustomSelect, DateInput, Input, TextAreaInput, TitleText, BooleanInput} from '../../common'
import {OptionItem, TherapyGeneralInfo} from '../../../utils/types'
import {ButtonStyle} from '../../../style/button'
import {i18n} from '../../../i18n'
import {Button} from 'antd'
import {sharedStyles} from '../../../style/shared_styles'
import {TherapyForm, TherapyIntention} from '../../../utils/enums'
import {buildEnumOptions, buildSelectDoctors, buildSelectOptions, fromJson} from '../../../utils/helper'
import {TherapyKeys, therapyMandatoryFields} from './utils/keys'
import {observer} from 'mobx-react'
import {useForm, useLoading} from '../../../utils/hooks'
import {useStores} from '../../../store'
import {MAX_HEIGHT} from '../../../utils/constants'
import {format, isBefore, differenceInDays, addDays} from 'date-fns'
import {Color} from '../../../style/custom/colors'

// Standardwerte setzen
const defaultTherapy: Partial<TherapyGeneralInfo> = {
    intention: TherapyIntention.OTHER,
    therapyName: 'APAT',
    therapyForm: TherapyForm.OTHER,
    dateOfEntry: new Date(),
    consentGiven: true
};

/**
 * Component used to edit/create a therapy in a modal.
 */
const EditTherapy: React.FC<EditTherapyProps> = observer((props: EditTherapyProps) => {

    // Standardwerte mit ggf. schon gespeicherten mergen...
    const initialTherapy: TherapyGeneralInfo = {
        ...defaultTherapy,
        ...props.therapyInfo
    } as TherapyGeneralInfo;

    // ... Therapieendedatum berechnen (wird nicht vom Service geliefert, muss ich mal noch machen...)
    if (initialTherapy.startDate && initialTherapy.cycleDuration) {
        initialTherapy.endDate = addDays(initialTherapy.startDate, initialTherapy.cycleDuration - 1)
    }

    // ... und laden
    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(initialTherapy, therapyMandatoryFields)
    const [editLoading, setEditIsLoading] = useLoading(false)
    const [takeOverHistoryData, setTakeOverHistoryData] = useState<boolean>(false);

    const {therapyStore, patientStore, userStore, doctorStore} = useStores()
    const [previousTherapy] = useState<TherapyGeneralInfo | undefined>(therapyStore.therapyComplete?.generalInfo)
    const [warningMessage, setWarningMessage] = useState<string>('')

    const isCreate = !props.therapyInfo

    const therapyIntentionOptions: OptionItem[] = buildEnumOptions(TherapyIntention, 'therapy.intention')
    const therapyFormOptions: OptionItem[] = buildEnumOptions(TherapyForm, 'therapy.form')
    const therapyNameOptions: OptionItem[] = buildSelectOptions(userStore.getTherapyNameList)
    const therapyDoctorOptions: OptionItem[] = buildSelectDoctors(doctorStore.getDoctors)

    const onTakeOverHistoryDataChanged = (value: boolean) => {
        // console.log('##')
        // console.log('takeOverHistoryData:', takeOverHistoryData);
        setTakeOverHistoryData(value);
        // console.log('Checkbox value changed to:', value);
        // console.log('takeOverHistoryData:', takeOverHistoryData);
    };
    
    const onSubmit = async (copyTherapy: boolean) => {
        const isValid = onValidateInputs()
        if (!isValid) {
            return
        }

        // const cycleNumber = 1
        const cycleDurationInDays = differenceInDays(formState.values.endDate, formState.values.startDate) + 1

        const data = {
            ...(formState.values as TherapyGeneralInfo),
            startDateString: formState.values.startDate ? format(formState.values.startDate, 'yyyy-MM-dd') : '',
            endDateString: formState.values.endDate ? format(formState.values.endDate, 'yyyy-MM-dd') : '',
            dateOfEntryString: formState.values.dateOfEntry ? format(formState.values.dateOfEntry, 'yyyy-MM-dd') : '',
            patientId: patientStore.patientOverview?.patient.id || '',
            cycleNumber: formState.values.cycleNumber,
            cycleDuration: cycleDurationInDays,
            consentGiven: !!formState.values.consentGiven
        }

        setEditIsLoading(true)
        const isSuccess = await therapyStore.createUpdateTherapy(data, copyTherapy ? previousTherapy?.id : undefined)
        setEditIsLoading(false)

        if (isSuccess) {
            props.onCloseModal?.()
        }
    }

    useEffect(() => {
        const getDoctors = async () => {
            await doctorStore.getTherapyDoctorList()
        }
        getDoctors()
    }, [])

    // prefill select options with values from previous therapy or with default one
    useEffect(() => {
        inputChangeHandler(previousTherapy?.intention || TherapyIntention.OTHER, TherapyKeys.intention)
        inputChangeHandler(previousTherapy?.therapyForm || TherapyForm.OTHER, TherapyKeys.therapyForm)
        inputChangeHandler(previousTherapy?.therapyName || 'APAT', TherapyKeys.therapyName)
        inputChangeHandler(previousTherapy?.height || null, TherapyKeys.height)
        inputChangeHandler(previousTherapy?.weight || null, TherapyKeys.weight)
        inputChangeHandler(previousTherapy?.bodySurface || null, TherapyKeys.bodySurface)
        inputChangeHandler(previousTherapy?.cycleDuration || null, TherapyKeys.cycleDuration)
        inputChangeHandler(previousTherapy?.cycleNumber || null, TherapyKeys.cycleNumber)
        inputChangeHandler(previousTherapy?.doctor || null, TherapyKeys.doctor)
    }, [])

    const checkWarningMessage = () => {
        // die Warnungen sehen mistig aus. Man muss echt klicken, damit die weg geht! Ich lass die erstmal weg.
        // if (formState.values.startDate && isBefore(formState.values.startDate, new Date())) {
        //     setWarningMessage(i18n.t('common.warnings.pastDate'))
        // }
        // if (formState.values.endDate && isBefore(formState.values.endDate, new Date())) {
        //     setWarningMessage(i18n.t('common.warnings.pastDate'))
        // }
    }

    return (
        <div>
            <TitleText text={i18n.t('therapyTab.basicSection.edit.therapySection')} />
            <div style={{display: 'flex'}}>                
                <div style={sharedStyles.oneColumn}>
                {/* <CustomSelect
                        label={i18n.t('therapyTab.basicSection.intention')}
                        items={therapyIntentionOptions}
                        {...extractProps(TherapyKeys.intention)} 
                        disabled={true}/>
                <CustomSelect
                    label={i18n.t('therapyTab.basicSection.form')}
                    items={therapyFormOptions}
                    {...extractProps(TherapyKeys.therapyForm)}
                    disabled={true}/> */}
                {/* <Input 
                    label={i18n.t('therapyTab.basicSection.name')} 
                    type={'text'} 
                    {...extractProps(TherapyKeys.therapyName)} /> */}
                <Input
                        label={i18n.t('therapyTab.basicSection.cycleNumber')}
                        placeholder={i18n.t('therapyTab.basicSection.cycleNumberPlaceholder')}
                        min={1}
                        isInteger
                        type={'number'}
                        {...extractProps(TherapyKeys.cycleNumber)}
                    />                
                <DateInput
                    label={i18n.t('therapyTab.basicSection.startDate')}
                    max={formState.values[TherapyKeys.endDate]}
                    {...extractProps(TherapyKeys.startDate)}
                    onBlur={checkWarningMessage} />
                <DateInput
                    label={i18n.t('therapyTab.basicSection.endDate')}
                    min={formState.values[TherapyKeys.startDate]}
                    {...extractProps(TherapyKeys.endDate)}
                    onBlur={checkWarningMessage} />
                {/* <Input
                    label={i18n.t('therapyTab.basicSection.consentGiven.title')}
                    type={'checkbox'}
                    {...extractProps(TherapyKeys.consentGiven)}
                    extraInfo={i18n.t('therapyTab.basicSection.consentGiven.extra')} /> */}
                <CustomSelect
                    label={i18n.t('therapyTab.basicSection.doctor.label')}
                    enableSearch={true}
                    items={therapyDoctorOptions}
                    {...extractProps(TherapyKeys.doctor)}
                    onValueChanged={(val, err) => inputChangeHandler(val ? fromJson(val) : val, TherapyKeys.doctor, err)}
                    value={formState.values[TherapyKeys.doctor]?.name} />
                <TextAreaInput
                    label={i18n.t('therapyTab.basicSection.comment')}
                    {...extractProps(TherapyKeys.comment)} />
                {isCreate && therapyStore.hasTherapyMedication && (
                    <BooleanInput
                        label={i18n.t('therapyTab.basicSection.takeOverHistoryData.title')}
                        checked={takeOverHistoryData}
                        onValueChanged={onTakeOverHistoryDataChanged}/>
                )}
                </div>
                {
                // Löschen trau ich mich noch nicht, deshalb erstmal alles auskommentieren... 😁 
                }
                {/* <div style={sharedStyles.leftColumn}>
                    <Input
                        label={i18n.t('therapyTab.basicSection.cycleNumber')}
                        min={1}
                        isInteger
                        type={'number'}
                        {...extractProps(TherapyKeys.cycleNumber)}
                    />
                </div> */}
                {/* <div style={sharedStyles.rightColumn}>
                    <Input
                        label={i18n.t('therapyTab.basicSection.cycle')}
                        min={1}
                        isInteger
                        type={'number'}
                        {...extractProps(TherapyKeys.cycleDuration)}
                    />
                    {!isCreate && (
                        <>
                            <Input
                                label={i18n.t('therapyTab.basicSection.useOriginalDose')}
                                type={'checkbox'}
                                hideErrorField={true}
                                {...extractProps(TherapyKeys.useOriginalDose)}
                            />
                        </>
                    )}
                </div> */}
            </div>

            <div>
                <TitleText text={i18n.t('therapyTab.basicSection.edit.patientSection')} />
                <div style={{display: 'flex'}}>
                    <div style={sharedStyles.oneColumn}>
                        <Input
                            label={i18n.t('therapyTab.basicSection.weight')}
                            min={0}
                            max={userStore.getMaxWeight}
                            type={'number'}
                            {...extractProps(TherapyKeys.weight)}
                        />
                        <Input
                            label={i18n.t('therapyTab.basicSection.height')}
                            max={MAX_HEIGHT}
                            min={0}
                            type={'number'}
                            isInteger
                            {...extractProps(TherapyKeys.height)}
                            pixelAlignTop={true}
                        />
                    </div>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={sharedStyles.actionBtnLeft}>
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={props.onCloseModal}>
                        {i18n.t('button.cancel')}
                    </Button>
                </div>

                <div style={sharedStyles.actionBtnRight}>
                    <Button type={'primary'} 
                            shape={'round'} 
                            loading={editLoading} 
                            style={ButtonStyle.save} 
                            onClick={async () => onSubmit(takeOverHistoryData)}>
                        {i18n.t('button.save')}
                    </Button>
                </div>
            </div>
            <CustomModal open={!!warningMessage} title={i18n.t('notification.warning.title')}>
                <div style={{...sharedStyles.column, alignItems: 'center'}}>
                    <p key={'warningMessage'} style={{color: Color.warningYellow, fontSize: 22}}>
                        {warningMessage}
                    </p>
                    <Button type={'default'} shape={'round'} style={{...ButtonStyle.primary, marginTop: 25}} onClick={() => setWarningMessage('')}>
                        {i18n.t('button.close')}
                    </Button>
                </div>
            </CustomModal>
        </div>
    )
})

type EditTherapyProps = {
    therapyInfo?: TherapyGeneralInfo
    onCloseModal?: () => void
}

export default EditTherapy
